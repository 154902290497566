<template>
	<div>
		<div class="card align-items-center px-1 py-0 flex-lg-row justify-content-md-between">
			<ul class="nav nav-tabs align-items-center gap-x-2 mb-0 w-100" roles="tabslits">
				<b-nav-item
					v-for="(tab, index) in tabs"
					:key="index"
					class="x-tab-width"
					link-classes="px-1 justify-content-between w-100"
					:active="tab.status == statusTab"
					@click="changeTab(tab.status)"
				>
					<span>{{ tab.name }}</span>
				</b-nav-item>
			</ul>
		</div>

		<b-card no-body class="mb-1">
			<div class="m-2">
				<!-- Paginator -->
				<div class="mx-2 mb-2">
					<b-row>
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-start pl-0"
						>
							<span class="text-muted">
								Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
							</span>
						</b-col>
						<!-- Pagination -->
						<b-col
							cols="12"
							sm="6"
							class="d-flex align-items-center justify-content-center justify-content-sm-end"
						>
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								class="mb-0 mt-1 mt-sm-0"
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</b-col>
					</b-row>
				</div>

				<b-row>
					<b-col cols="12" xl="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="perpage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="resetSearch" />
					</b-col>

					<!-- Search -->
					<b-col cols="12" xl="8" class="mt-1">
						<div class="container-btns-page-specimens d-flex justify-content-end">
							<div class="d-flex">
								<b-form-input
									v-model="search_input"
									class="d-inline-block mr-1"
									placeholder="Codigo o responsable..."
									debounce="500"
									@keyup.enter="$refs['table-petty-cash'].refresh()"
								/>
								<!-- @keyup.enter="$refs.refUserListTable.refresh()" -->
								<!-- <b-button
									v-ripple.400="'rgba(113, 102, 240, 0.15)'"
									variant="outline-primary"
									class="btn-icon mr-1"
									id="filters"
									@click="filterController = !filterController"
								>
									<feather-icon icon="FilterIcon" />
								</b-button> -->
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive">
				<b-table
					:items="myProvider"
					:fields="showFields"
					sticky-header="75vh"
					primary-key="id"
					:current-page="current_page"
					:per-page="perpage"
					responsive
					small
					show-empty
					ref="table-petty-cash"
				>
					<template #table-busy>
						<div class="text-center text-primary my-2">
							<b-spinner class="align-middle"></b-spinner>
							<strong>Cargando...</strong>
						</div>
					</template>
					<template #cell(code)="data">
						<div class="d-flex">
							<span>{{ data.item.code }}</span>
							<feather-icon
								v-if="data.item.amount - data.item.expenses <= data.item.balance_min"
								v-b-tooltip.hover
								:title="`Saldo minimo menor a ${data.item.balance_min}`"
								class="text-danger ml-1"
								size="18"
								icon="AlertCircleIcon"
							/>
						</div>
					</template>
					<template #cell(responsible)="data">
						<span>{{ data.item.responsible }}</span>
					</template>
					<template #cell(date)="data">
						<div class="d-flex align-items-center justify-content-center">
							<feather-icon icon="CalendarIcon" class="text-success" />
							<span style="margin-left: 5px">{{ data.item.date | myGlobalDayShort }}</span>
						</div>
					</template>
					<template #cell(amount)="data">
						<div class="d-flex justify-content-center align-items-center">
							<span class="text-success mr-1 text-right" style="font-size: 13px; width: 100px">
								{{ data.item.amount | currency }}
							</span>
							<div style="width: 25px">
								<feather-icon
									v-if="data.item.amount > 0"
									icon="ListIcon"
									class="text-success cursor-pointer"
									@click="showTrackingInputs(data.item, 'input')"
								/>
							</div>
						</div>
					</template>
					<template #cell(expenses)="data">
						<div class="d-flex justify-content-center align-items-center">
							<span class="text-black mr-1 text-right" style="font-size: 13px; width: 100px">
								- {{ data.item.expenses | currency }}
							</span>
							<div style="width: 25px">
								<feather-icon
									v-if="data.item.expenses > 0"
									icon="ListIcon"
									class="text-black cursor-pointer"
									@click="showTrackingInputs(data.item, 'output')"
								/>
							</div>
						</div>
					</template>
					<template #cell(balance)="data">
						<div class="d-flex justify-content-center">
							<span
								class="text-primary"
								:class="data.item.amount - data.item.expenses < 0 ? 'text-danger' : 'text-primary'"
								style="font-size: 13px"
							>
								{{ (data.item.amount - data.item.expenses) | currency }}
							</span>
						</div>
					</template>
					<template #cell(status)="data">
						<div class="d-flex justify-content-center">
							<b-badge :variant="resolveStatus(data.item.status)">{{ data.item.status }}</b-badge>
						</div>
					</template>
					<template #cell(created_by)="data">
						<div class="d-flex flex-column align-items-center justify-content-center">
							<span>{{ data.item.created_by }}</span>
							<span style="font-size: 13px !important">
								<feather-icon icon="CalendarIcon" class="text-success" />
								{{ data.item.created_at | myGlobalDayWithHour }}
							</span>
						</div>
					</template>
					<template #cell(updated_by)="data">
						<div class="d-flex flex-column align-items-center justify-content-center">
							<span>{{ data.item.updated_by }}</span>
							<span style="font-size: 13px !important">
								<feather-icon icon="CalendarIcon" class="text-success" />
								{{ data.item.updated_at | myGlobalDayWithHour }}
							</span>
						</div>
					</template>
					<template #cell(deleted_by)="data">
						<div class="d-flex flex-column align-items-center justify-content-center">
							<span>{{ data.item.deleted_by }}</span>
							<span style="font-size: 13px !important">
								<feather-icon icon="CalendarIcon" class="text-success" />
								{{ data.item.deleted_at | myGlobalDayWithHour }}
							</span>
						</div>
					</template>
					<template #cell(actions)="data">
						<div class="d-flex justify-content-center align-items-center">
							<b-button
								v-if="data.item.status == 'activo'"
								class="btn-icon btn-sm d-flex align-items-center"
								variant="success"
								@click="addCashInput(data.item, 'input')"
							>
								+
								<feather-icon icon="DollarSignIcon" />
							</b-button>
							<b-button
								v-if="data.item.status == 'activo'"
								class="btn-icon btn-sm ml-1 d-flex align-items-center"
								variant="warning"
								@click="addCashInput(data.item, 'output')"
							>
								-
								<feather-icon icon="DollarSignIcon" />
							</b-button>
							<b-button
								v-if="data.item.status == 'activo'"
								class="btn-icon btn-sm ml-1"
								variant="primary"
								@click="fnClosePettyCash(data.item)"
							>
								<feather-icon icon="LogOutIcon" />
							</b-button>
							<b-button
								v-if="data.item.status == 'activo'"
								variant="danger"
								class="btn-icon ml-1 btn-sm"
								@click="deletePettyCash(data.item)"
							>
								<feather-icon icon="Trash2Icon" />
							</b-button>
						</div>
					</template>

					<template #custom-foot>
						<tr class="sticky-data bg-light">
							<td colspan="3" class="text-right">TOTAL:</td>
							<td>
								<div class="d-flex justify-content-center text-success" style="font-weight: bold">
									{{ totalDeposit | currency }}
								</div>
							</td>
							<td>
								<div class="d-flex justify-content-center text-black" style="font-weight: bold">
									{{ totalExpenses | currency }}
								</div>
							</td>
							<td>
								<div
									class="d-flex justify-content-center text-primary"
									:class="getBalance < 0 ? 'text-danger' : 'text-primary'"
									style="font-weight: bold"
								>
									{{ getBalance | currency }}
								</div>
							</td>
							<td colspan="3"></td>
						</tr>
					</template>
				</b-table>
			</div>
		</b-card>

		<TrackingInputsAmountModal
			v-if="trackingAmountModal"
			:pettyCash="modal.pettyCash"
			:typeTransaction="typeTransaction"
			@close="trackingAmountModal = false"
			@refresh="resetSearch()"
		/>

		<CashInputModal
			v-if="cashInputModal"
			:pettyCash="modal.pettyCash"
			:typeTransaction="typeTransaction"
			@close="cashInputModal = false"
			@registered="resetSearch(), (cashInputModal = false)"
		/>
		<PettyCashModal
			v-if="pettyCashModal"
			:pettyCash="modal.pettyCash"
			@close="pettyCashModal = false"
			@registered="resetSearch(), (pettyCashModal = false)"
		/>

		<ClosePettyCashModal
			v-if="closePettyCash"
			:pettyCash="modal.closePettyCash"
			@close="closePettyCash = false"
			@closed="resetSearch(), (closePettyCash = false)"
		/>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import Fields from "@/views/amg/management/views/petty-cash/data/fields.data.js"
import TrackingInputsAmountModal from "@/views/amg/management/views/petty-cash/components/TrackingInputsAmountModal.vue"
import CashInputModal from "@/views/amg/management/views/petty-cash/components/CashInputModal.vue"
import PettyCashModal from "@/views/amg/management/views/petty-cash/components/PettyCashModal.vue"
import ClosePettyCashModal from "@/views/amg/management/views/petty-cash/components/ClosePettyCashModal.vue"

export default {
	directives: {
		Ripple,
	},
	components: {
		TrackingInputsAmountModal,
		CashInputModal,
		PettyCashModal,
		ClosePettyCashModal,
	},
	props: {},
	data() {
		return {
			start_page: 0,
			end_page: 0,
			total_data: 0,
			perpage: 100,
			next_page: "",
			current_page: 1,
			to_page: 0,
			perPageOptions: [10, 25, 50, 100],
			search_input: "",
			filterController: false,
			fields: Fields,
			trackingAmountModal: false,
			cashInputModal: false,
			pettyCashModal: false,
			closePettyCash: false,
			typeTransaction: "input",
			modal: {
				pettyCash: {},
				cashInput: {},
				closePettyCash: {},
			},
			totalDeposit: 0,
			totalExpenses: 0,
			tabs: [
				{ id: 1, status: "activo", name: "ACTIVO", counter: 0 },
				{ id: 2, status: "cerrado", name: "CERRADO", counter: 0 },
				{ id: 3, status: "anulado", name: "ANULADO", counter: 0 },
			],
			statusTab: "activo",
		}
	},
	computed: {
		showFields() {
			return this.fields.filter((f) => f.visible)
		},
		getBalance() {
			this.fields.map((f) => {
				f.visible = true
				return f
			})

			switch (this.statusTab) {
				case "activo":
					this.fields[7].visible = false
					this.fields[8].visible = false
					break
				case "cerrado":
					this.fields[8].visible = false
					this.fields[9].visible = false
					break
				default:
					this.fields[7].visible = false
					this.fields[9].visible = false
					break
			}
			return this.totalDeposit - this.totalExpenses
		},
	},
	methods: {
		async myProvider(ctx) {
			this.isPreloading()
			const params = {
				search: this.search_input ? this.search_input : "",
				responsible_id: null,
				page: ctx.currentPage,
				perpage: ctx.perPage,
				status: this.statusTab,
			}

			try {
				const { data } = await PettyCashService.getPettyCash(params)

				this.start_page = data.from
				this.to_page = data.to
				this.total_data = data.total
				this.current_page = data.current_page
				this.totalDeposit = data.data.length ? data.data[0].sum_in : 0
				this.totalExpenses = data.data.length ? data.data[0].sum_out : 0
				return data.data
			} catch (error) {
				console.log(error)
				return []
			} finally {
				this.isPreloading(false)
			}
		},

		showTrackingInputs(item, type = "input") {
			this.typeTransaction = type
			this.trackingAmountModal = true
			this.modal.pettyCash = item
		},

		addCashInput(item, type = "input") {
			this.typeTransaction = type
			this.cashInputModal = true
			this.modal.pettyCash = item
		},

		resetSearch() {
			this.$refs["table-petty-cash"].refresh()
		},

		createPettyCash() {
			this.pettyCashModal = true
		},

		resolveStatus(status) {
			switch (status) {
				case "activo":
					return "success"
					break
				case "cerrado":
					return "primary"
				default:
					return "danger"
					break
			}
		},

		async deletePettyCash(item) {
			const confirm = await this.showConfirmSwal({
				text: "Eliminar caja chica",
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await PettyCashService.deletePettyCash(item.id)
				this.showSuccessToast("Caja chica eliminado correctamente")
				this.resetSearch()
			} catch (error) {
				this.showErrorSwal(error.response?.data)
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async fnClosePettyCash(item) {
			this.modal.closePettyCash = item
			this.closePettyCash = true
		},

		changeTab(status) {
			this.statusTab = status
			this.resetSearch()
		},
		resetFields() {},
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.sticky-data {
	position: sticky;
	bottom: 0;
	z-index: 1000;
}

.x-tab-width {
	width: 100%;
	@media screen and (min-width: 600px) {
		width: auto;
	}
}
</style>

<style lang="scss">
.container-btns-page-specimens {
	// background: red;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 900px) {
		flex-direction: row;
		justify-content: space-between;
	}
	@media screen and (min-width: 1500px) {
		flex-direction: row;
		justify-content: flex-end;
	}
	.btns {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-basis: auto;
		@media screen and (min-width: 600px) {
			flex-direction: row;
		}
		.btn {
			width: 100%;
			min-width: 180px;
			margin-top: 1rem;
			@media screen and (min-width: 900px) {
				margin-top: 0;
			}
		}
	}
}
</style>
