<template>
	<div>
		<b-modal
			ref="modal-tracking-inputs-amount"
			@hidden="$emit('close')"
			:title="getTitle"
			cancel-title="Cancelar"
			scrollable
			hide-footer
			size="xmd"
		>
			<div>
				<b-row>
					<b-col md="6">
						<b-form-group>
							<label>Fecha</label>
							<div
								class="d-flex align-items-center rounded pl-1"
								style="height: 35px; border: 1px solid rgba(34, 41, 47, 0.2)"
							>
								{{ pettyCash.date | myGlobalDayShort }}
							</div>
						</b-form-group>
					</b-col>

					<b-col md="6">
						<b-form-group>
							<label>Responsable</label>
							<div
								class="d-flex align-items-center rounded pl-1"
								style="height: 35px; border: 1px solid rgba(34, 41, 47, 0.2)"
							>
								{{ pettyCash.responsible }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row class="mb-1 mt-1">
					<b-col class="d-flex align-items-center">
						<small class="text-secondary">
							Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
						</small>
					</b-col>
					<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
						<b-pagination
							v-model="pagination.curPage"
							:total-rows="pagination.total"
							:per-page="pagination.perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col cols="12" xl="3" class="d-flex align-items-center justify-content-start mb-1 mb-xl-0">
						<label>Mostrar</label>
						<v-select
							v-model="pagination.perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							style="width: 90px"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
					</b-col>
					<b-col
						cols="12"
						xl="5"
						class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-center gap-x-2 mb-1 mb-xl-0"
					>
						<div class="d-flex align-items-center">
							<b-form-datepicker
								class="border-primary"
								style="width: 150px"
								locale="en-US"
								placeholder="Desde..."
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekFrom"
							/>
							<b-form-datepicker
								class="border-primary ml-1"
								locale="en-US"
								style="width: 150px"
								placeholder="Hasta..."
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekTo"
							/>

							<span @click="cleanFilters()" class="ml-1 cursor-pointer">
								<v-icon name="gi-broom"></v-icon>
								Limpiar
							</span>
						</div>
					</b-col>
					<b-col cols="12" xl="4" v-if="typeTransaction === 'output'">
						<div class="container-btns-page-specimens">
							<div class="d-flex w-100">
								<b-form-input
									v-model="search"
									class="d-inline-block mr-1"
									placeholder="Buscar..."
									@keyup.enter="getTracking"
									debounce="500"
								/>
							</div>
						</div>
					</b-col>
				</b-row>

				<b-table-simple responsive sticky-header="50vh" no-border-collapse>
					<b-thead class="sticky-header">
						<b-th v-if="typeTransaction != 'input'">Description</b-th>
						<b-th v-if="typeTransaction != 'input'" class="text-center">Sustento</b-th>
						<b-th class="text-center">Monto</b-th>
						<b-th v-if="typeTransaction != 'input'">Observacion</b-th>
						<b-th class="text-center">Creado por</b-th>
						<b-th class="text-center">Acciones</b-th>
					</b-thead>
					<b-tbody>
						<template v-for="transac in getTransactions">
							<b-tr>
								<b-td v-if="typeTransaction != 'input'">
									{{ transac.description ? transac.description : "-" }}
								</b-td>
								<b-td v-if="typeTransaction != 'input'">
									<div class="d-flex">
										<ViewImage :specimen="transac" />

										<div
											v-if="transac.url != null"
											class="d-flex flex-column justify-content-center"
											style="margin-left: 5px"
										>
											<a :href="transac.url" download target="_blank">
												<feather-icon
													icon="DownloadIcon"
													class="text-danger cursor-pointer"
													size="17"
												/>
											</a>
										</div>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex justify-content-center">
										<span class="text-primary">{{ transac.amount | currency }}</span>
									</div>
								</b-td>
								<b-td v-if="typeTransaction != 'input'">
									{{ transac.observation ? transac.observation : "-" }}
								</b-td>
								<b-td>
									<div class="d-flex flex-column align-items-center justify-content-center">
										<span>{{ transac.created_by }}</span>
										<span style="font-size: 13px">
											<feather-icon icon="CalendarIcon" class="text-success" />
											{{ transac.created_at | myGlobalDayWithHour }}
										</span>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex align-items-center justify-content-center">
										<b-button
											v-if="pettyCash.status == 'activo'"
											variant="danger"
											class="btn-icon btn-sm"
											@click="deleteTransaction(transac)"
										>
											<feather-icon icon="Trash2Icon" />
										</b-button>

										<b-button
											v-if="transac.description_id == null && typeTransaction == 'output'"
											variant="primary"
											class="btn-icon btn-sm ml-1"
											@click="addDescription(transac)"
										>
											<feather-icon icon="StarIcon" />
										</b-button>
									</div>
								</b-td>
							</b-tr>
						</template>
					</b-tbody>
				</b-table-simple>
			</div>
			<!-- <template #modal-footer>
			<b-button @click="$emit('close')">Cancelar</b-button>
		</template> -->
		</b-modal>

		<DescriptionPettyCashModal
			v-if="descriptionModal"
			:transaction="transactionSelected"
			@close="descriptionModal = false"
			@refresh=";(descriptionModal = false), getTracking()"
		/>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import DescriptionPettyCashModal from "@/views/amg/management/views/petty-cash/components/DescriptionPettyCashModal.vue"
import ViewImage from "@/components/commons/ViewImage.vue"

export default {
	props: {
		pettyCash: {
			required: true,
		},
		typeTransaction: {
			default: "input",
		},
	},
	directives: { Ripple },
	components: {
		DescriptionPettyCashModal,
		ViewImage,
	},
	mixins: [modalMixin],
	data() {
		return {
			transactions: [],
			descriptionModal: false,
			transactionSelected: {},
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 25,
				from: 0,
				to: 0,
			},
			weekFrom: null,
			weekTo: null,
			search: null,
		}
	},
	computed: {
		getTransactions() {
			const type = this.typeTransaction == "input" ? "input" : "output"
			return this.transactions.filter((t) => t.type == type)
		},
		getTitle() {
			if (this.typeTransaction == "input") {
				return "Depositos"
			}
			return "Gastos"
		},
	},
	async mounted() {
		this.toggleModal("modal-tracking-inputs-amount")
	},
	async created() {
		await this.getTracking()
	},
	methods: {
		cleanFilters() {
			this.weekFrom = null
			this.weekTo = null
		},
		async refresh() {
			await this.getTracking()
		},
		async getTracking() {
			this.isPreloading()
			try {
				const { data } = await PettyCashService.getTracking({
					id: this.pettyCash.id,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
					start_date: this.weekFrom ? this.weekFrom : null,
					end_date: this.weekTo ? this.weekTo : null,
					type: this.typeTransaction == "input" ? "input" : "output",
					search: this.search,
				})
				this.transactions = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async deleteTransaction(transac) {
			const confirm = await this.showConfirmSwal({
				text: "Eliminar transaccion",
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await PettyCashService.deleteTransaction(transac.id)
				this.showSuccessToast("Transaccion eliminado correctamente")
				this.getTracking()
				this.$emit("refresh")
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		addDescription(item) {
			this.descriptionModal = true
			this.transactionSelected = item
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getTracking()
		},
		"pagination.perPage": async function () {
			await this.getTracking()
		},
		weekFrom: async function () {
			await this.getTracking()
		},
		weekTo: async function () {
			await this.getTracking()
		},
	},
}
</script>

<style lang="scss" scoped>
.img-specimen {
	width: 50px;
	height: 50px;
	// margin-right: 1rem;
	// border-radius: 8px;
	// box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// padding: 0.4rem;
	object-fit: cover;
	&:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	cursor: pointer;
	@media screen and (min-width: 700px) {
		width: 70px;
		height: 70px;
		// background: red;
	}
}
</style>
